<template>
  <div>
    <a-card title="采购单详情">
      <a-button slot="extra" type="primary" style="margin-right: 8px;" ghost v-print="'#printContent'"> <a-icon type="printer" />打印</a-button>
      <a-button slot="extra" type="primary" ghost @click="() => { this.$router.go(-1); }"> <a-icon type="left" />返回</a-button>
      <section id="printContent">
        <a-spin :spinning="loading">
          <img id="barcode" style="float: right" />
          <a-descriptions bordered>
            <a-descriptions-item label="采购编号">
              {{ info.number }}
            </a-descriptions-item>
            <a-descriptions-item label="供应商">
              {{ info.supplier_name }}
            </a-descriptions-item>
            <a-descriptions-item label="仓库">
              {{ info.warehouse_name }}
            </a-descriptions-item>
            <a-descriptions-item label="创建人">
              {{ info.creator_name }}
            </a-descriptions-item>
            <a-descriptions-item label="处理日期">
              {{ info.handle_time }}
            </a-descriptions-item>
            <a-descriptions-item label="其他费用">
              {{ info.other_amount }}
            </a-descriptions-item>
            <a-descriptions-item label="备注">
              {{ info.remark }}
            </a-descriptions-item>
          </a-descriptions>
          <!-- <a-divider orientation="left" style="margin-top: 30px;">结算账户信息</a-divider>
          <a-table
            rowKey="id"
            size="middle"
            :columns="columnsAccount"
            :data-source="info.purchase_account_items"
            :pagination="false" /> -->
          <a-divider orientation="left" style="margin-top: 30px;">产品信息</a-divider>
          <a-table
            rowKey="id"
            size="middle"
            :columns="columns"
            :data-source="info.purchase_materials_items"
            :pagination="false" />

            

            <!-- 文件列表部分 -->
          <a-divider orientation="left" style="margin-top: 30px;">文件列表</a-divider>
          <div v-if="info.purchase_file_items && info.purchase_file_items.length > 0">
            <ul>
              <li v-for="(file, index) in info.purchase_file_items" :key="index">
                <a @click="handleFilePreview(file)">{{ file.name }}</a>
              </li>
            </ul>
          </div>
    

        </a-spin>
      </section>
    </a-card>
  </div>
</template>

<script>
  import Cookies from 'js-cookie';
  import { purchaseOrderDetail } from '@/api/purchasing'
  import JsBarcode from 'jsbarcode'
  import NP from 'number-precision'

export default {
    data() {
      return {
        Cookies,
        loading: false,
        materialLoading: false,
        receiptOrder: undefined,
        file_items: [],
        info: {},
        columns: [
          {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            width: 45,
            customRender: (value, item, index) => {
              return item.isTotal ? '合计' : (index + 1)
            },
          },
          {
            title: '物料名称',
            dataIndex: 'materials_name',
            key: 'materials_name',
            width: 150,
          },
          {
            title: '产品编号',
            dataIndex: 'materials_number',
            key: 'materials_number',
            width: 150,
          },
          {
            title: '单位',
            dataIndex: 'unit_name',
            key: 'unit_name',
            width: 80,
          },
          {
            title: '采购数量',
            dataIndex: 'purchase_quantity',
            key: 'purchase_quantity',
            width: 120,
          },
          {
            title: '采购单价(元)',
            dataIndex: 'purchase_price',
            key: 'purchase_price',
            width: 120,
          },
          {
            title: '金额',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            width: 200,
            customRender: (value, item) => {
              if (item.isTotal) return value;
              value = NP.times(item.purchase_quantity, item.purchase_price);
              return item.id ? NP.round(value, 2) : ''
            },
          }
        ],
        columnsAccount: [
          {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            width: 45,
            customRender: (value, item, index) => {
              return item.isTotal ? '合计' : (index + 1)
            },
          },
          {
            title: '结算账户',
            dataIndex: 'account_name',
            key: 'account_name',
            width: 200,
          },
          {
            title: '付款金额',
            dataIndex: 'payment_amount',
            key: 'payment_amount',
            width: 200,
          }
        ],
      }
    },
    // created(){
    //   this.initData();
    // },
    methods: {
      getJsBarcode(number) {
        JsBarcode("#barcode", number, {
          lineColor: '#000',
          width: 2,
          height: 40,
          displayValue: true
        });
      },
      initData() {
        this.loading = true;
        purchaseOrderDetail({ id: this.$route.query.id }).then(data => {
          this.info = data;
          this.info.purchase_account_items = [
            ...this.info.purchase_account_items,
            {
              id: '-1',
              isTotal: true,
              payment_amount: this.info.payment_amount,
            },
          ];
          console.log(123)
          this.info.purchase_materials_items = [
            ...this.info.purchase_materials_items,
            {
              id: '-1',
              isTotal: true,
              purchase_quantity: this.info.total_quantity,
              totalAmount: this.info.total_amount,
            },
          ];
         
          this.getJsBarcode(data.number)
        }).finally(() => {
          this.loading = false;
        });
      },

       // 文件相关的
    handleFilePreview(file) {
      console.log('file',file)
      window.open(file.file);
    },

    handleFileChange({ fileList }) {
      // console.log('fileList',fileList)
      this.$set(this.info, 'purchase_file_items', fileList);
    },

    beforeFileUpload(file) {
      // 在这里可以添加文件上传前的检查逻辑
      // const isAllowedType = ['application/pdf', 'image/jpeg', 'image/png'].includes(file.type);
      // if (!isAllowedType) {
      //   this.$message.error('只能上传 PDF 或图片文件!');
      //   return false;
      // }
      // return true;  // 返回 false 则停止上传
    },
    },
    mounted() {
      console.log("Hello");
      this.initData();
    },
  }
</script>
<style>
</style>
